var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"s-lsm",class:{'s-lsm--show': _vm.isOpen}},[_c('div',{staticClass:"s-lsm__selector",on:{"mouseenter":_vm.handleMouseEnter,"mouseleave":_vm.handleMouseLeave,"click":_vm.handleClick}},[_c('span',{staticClass:"s-lsm__label"},[_vm._t("default",null,null,{ currentLabel: _vm.currentLabel, isOpen: _vm.isOpen })],2),_vm._v(" "),_c('div',{ref:"lsSwitcher",staticClass:"s-lsm__select",class:{
        's-lsm__select--expand': _vm.isExpand,
        's-lsm__select--float': !_vm.isExpand
      }},_vm._l((_vm.storeViews),function(storeView,storeCode){return _c('div',{key:storeCode,staticClass:"s-lsm__option",class:{
          's-lsm--hidden': _vm.selectedLanguage === storeCode && !_vm.showAll,
          's-lsm--selected': _vm.selectedLanguage === storeCode && _vm.showAll
        }},[_c('span',{on:{"click":function($event){return _vm.selectLanguage(storeCode)}}},[(_vm.fullName)?[_vm._v("\n            "+_vm._s(storeView.i18n.fullLanguageName)+"\n          ")]:[_vm._v("\n            "+_vm._s(storeView.name)+"\n          ")]],2)])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }