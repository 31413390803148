
import { defineComponent, computed } from 'vue';
import { SfButton } from '@storefront-ui/vue';

/**
 * Атомарний компонент іконки акаунту.
 * Використовується для відображення кнопки акаунту користувача.
 * @displayName AAccountIcon
 * @example
 * <AAccountIcon
 *   :is-active="false"
 *   caption="Login"
 *   @click="handleClick"
 * />
 */
export default defineComponent({
  name: 'AAccountIcon',

  components: {
    SfButton
  },

  props: {
    /** Стан активності кнопки */
    isActive: {
      type: Boolean,
      default: false
    },
    /** Текст підпису */
    caption: {
      type: String,
      required: true
    }
  },

  emits: ['click'],

  setup (props, { emit }) {
    // Обчислювані класи для кнопки
    const buttonClasses = computed(() => ({
      'sf-button--pure': true,
      'a-account-icon--active': props.isActive
    }));

    // Обробник кліку
    const handleClick = () => {
      emit('click');
    };

    return {
      buttonClasses,
      handleClick
    };
  }
});
