<template>
  <div class="m-footer-bots">
    <p class="m-footer-bots__caption">
      {{ $t('Our bots') }}
    </p>
    <div class="m-footer-bots__group">
      <SfLink
        class="m-footer-bots__links m-footer-bots__telegram"
        target="_blank"
        rel="nofollow"
        :link="telegramLink"
      />
      <SfLink
        class="m-footer-bots__links m-footer-bots__viber"
        target="_blank"
        rel="nofollow"
        :link="viberLink"
      />
      <SfLink
        class="m-footer-bots__links m-footer-bots__messenger"
        target="_blank"
        rel="nofollow"
        :link="messengerLink"
      />
    </div>
  </div>
</template>

<script>
import {
  SfLink
} from '@storefront-ui/vue';
import config from 'config';

export default {
  name: 'MFooterBots',
  components: { SfLink },
  data () {
    return {
      telegramLink: config.socialLinks?.telegram || '#',
      messengerLink: config.socialLinks?.messenger || '#',
      viberLink: config.socialLinks?.viber || '#'
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import '~theme/css/fonts';

.m-footer-bots {
  &__caption {
    font: var(--font-medium) var(--font-sm) var(--font-family-inter);
    margin: 0 0 var(--spacer-10) 0;

    @media (min-width: $tablet-min) {
      margin: 0 0 var(--spacer-sm) 0;
    }

    @media (min-width: $tablet-min) and (max-width: $tablet-max) {
      font-size: var(--font-base);
    }
  }

  &__group {
    display: flex;
    align-items: center;
  }

  &__links {
    display: inline-flex;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 1px solid var(--black);
    margin-right: var(--spacer-sm);
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    position: relative;
    text-align: center;

    &:hover, &:focus {
      border-color: var(--orange-hover);

      &:before {
        color: var(--orange-hover);
      }
    }
  }

  &__telegram {
    @include font-icon(var(--icon-telegram));

    &:before {
      font-size: var(--font-size-16);
      color: var(--black);
      position: absolute;
      right: 0;
      left: -2px;
    }
  }

  &__viber {
    @include font-icon(var(--icon-viber));
    color: var(--black);
    font-size: var(--font-size-18);
  }

  &__messenger {
    @include font-icon(var(--icon-messenger));

    &:before {
      font-size: var(--font-size-10);
      color: var(--black);
    }
  }
}
</style>
