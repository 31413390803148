<template>
  <div
    v-if="isBanner"
    ref="bannerMenu"
    class="menu-banner-wrapper"
    @click="goToBannerLink"
  >
    <div class="sf-banner-menu">
      <MBannerCarousel
        :banners="banner"
        :isCustomHeight="true"
      />
    </div>
  </div>
</template>
<script>
import DeviceType from 'theme/mixins/DeviceType';
import { mapGetters, mapState } from 'vuex';
import GoogleTagManager from 'theme/mixins/gtm';
import MBannerCarousel from 'theme/components/molecules/m-banner-carousel';

export default {
  name: 'MBannerMenu',
  mixins: [DeviceType, GoogleTagManager],
  components: {
    MBannerCarousel
  },
  props: {
    categoryId: {
      type: Number,
      required: true
    },
    bannerIsShowed: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      getCategories: state => state.category.list
    }),
    ...mapGetters({
      getMenuBanners: 'promoted/getMenuBanners'
    }),
    isBanner () {
      return this.banner.length && (!this.isDesktop || this.bannerIsShowed)
    },
    banner () {
      return this.getMenuBanners.filter(e => e.category_ids?.includes(this.categoryId))
    },
    bannerCategories () {
      return this.getCategories.filter(e => (this.banner.category_ids || []).includes(e.id))
        .map(e => e.name) || []
    }
  },
  methods: {
    goToBannerLink (e) {
      const element = e.target;

      if (element.classList.contains('sf-bullet')) {
        e.stopPropagation();
        return;
      }

      this.closeCatalog();
    },
    closeCatalog () {
      this.$store.commit('ui/closeCatalog');
      this.$store.commit('ui/closeMenu');
      this.$emit('close')
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.menu-banner-wrapper .sf-banner.sf-banner-menu {
  align-items: flex-start;
  padding: var(--spacer-24) var(--spacer-40) var(--spacer-24) var(--spacer-24);
  background-size: contain;

  @include for-mobile {
    min-width: 100%;
    background-position: bottom right;
    background-size: contain;
  }
}

.sf-banner-menu {
  ::v-deep {
    .banner-wrapper {
      @include for-mobile {
        height: 100%;
      }
    }

    .sf-banner.sf-banner-plp {
      padding: 0;
      color: var(--black2);
      background-position-x: center;

      @include for-mobile {
        align-items: var(--banner-align-items, center);
        min-width: 335px;
        height: 100%;
      }

      @include for-desktop {
        align-items: var(--banner-align-items, flex-start);
        height: 400px;
      }

      &__title {
        margin-top: 0;
        margin-bottom: var(--spacer-37);
        line-height: var(--font-size-25);
        font-size: var(--font-size-18);
        font-weight: var(--font-medium);
        max-width: 210px;

        @include for-desktop {
          margin-bottom: var(--spacer-58);
          max-width: 100%;
        }
      }

      &__description {
        color: var(--blue-dark);
        line-height: var(--font-size-20);
        margin-top: 0;
        font-size: var(--font-size-17);
        font-weight: var(--font-medium);

        @include for-mobile {
          margin-bottom: var(--spacer-25);
        }

        @include for-desktop {
          margin-bottom: var(--spacer-30);
        }
      }

      .sf-banner__container {

        @include for-desktop {
          flex: 0 0 var(--banner-container-width, 100%);
        }

        @include for-mobile {
          flex: 0 0 var(--banner-container-width, 90%);
        }

        .sf-button-main {
          font-size: var(--font-sm);
          padding: 1rem var(--spacer-sm);
          height: 36px;
          max-width: 98px;
        }
      }
    }
  }
}
</style>
