<template>
  <div
    class="a-skeleton-loader"
    :style="{
      width: width,
      height: height,
      borderRadius: radius
    }"
  />
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ASkeletonLoader',

  props: {
    /**
     * Ширина плейсхолдера (можна вказати в будь-яких одиницях: px, %, rem, etc.)
     */
    width: {
      type: String,
      default: '100%'
    },
    /**
     * Висота плейсхолдера
     */
    height: {
      type: String,
      default: '20px'
    },
    /**
     * Заокруглення кутів
     */
    radius: {
      type: String,
      default: '4px'
    }
  }
});
</script>

<style lang="scss" scoped>
.a-skeleton-loader {
  background: linear-gradient(
    90deg,
    var(--skeleton-base-color, #eeeeee) 25%,
    var(--skeleton-highlight-color, #f5f5f5) 37%,
    var(--skeleton-base-color, #eeeeee) 63%
  );
  background-size: 400% 100%;
  animation: skeleton-loading 1.4s ease infinite;
}

@keyframes skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
</style>
