<template>
  <div class="s-lsm" :class="{'s-lsm--show': isOpen}">
    <div class="s-lsm__selector"
       @mouseenter="handleMouseEnter"
       @mouseleave="handleMouseLeave"
       @click="handleClick"
    >
      <span class="s-lsm__label">
        <slot v-bind="{ currentLabel, isOpen }" />
      </span>

      <div
        ref="lsSwitcher"
        class="s-lsm__select"
        :class="{
          's-lsm__select--expand': isExpand,
          's-lsm__select--float': !isExpand
        }"
      >
        <div
          v-for="(storeView, storeCode) in storeViews"
          :key="storeCode"
          :class="{
            's-lsm--hidden': selectedLanguage === storeCode && !showAll,
            's-lsm--selected': selectedLanguage === storeCode && showAll
          }"
          class="s-lsm__option"
        >
          <span @click="selectLanguage(storeCode)">
            <template v-if="fullName">
              {{ storeView.i18n.fullLanguageName }}
            </template>
            <template v-else>
              {{ storeView.name }}
            </template>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, onUnmounted, ref, watch } from 'vue';
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { getLocalStorageLanguage, getStoreCodeLink, setLocalStorageLanguage } from 'theme/helpers/language';
import config from 'config';

export default defineComponent({
  name: 'MLanguageSwitcher',
  props: {
    showAll: {
      type: Boolean,
      default: false
    },
    fullName: {
      type: Boolean,
      default: false
    },
    clickable: {
      type: Boolean,
      default: false
    },
    isExpand: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const selectedLanguage = ref(currentStoreView().storeCode)
    const isOpen = ref(false)
    const lsSwitcher = ref(null)
    const openTimer = ref(null)

    const currentLabel = computed(() =>
      (
        props.fullName
          ? config.storeViews?.[selectedLanguage.value]?.i18n?.fullLanguageName
          : config.storeViews?.[selectedLanguage.value]?.name
      )
    )

    const storeViews = computed(() => {
      return Object.keys(config.storeViews).reverse().reduce((storeViews, storeCode) => {
        if (isValidStoreCode(storeCode)) {
          storeViews[storeCode] = config.storeViews[storeCode]
        }
        return storeViews
      }, {})
    })

    onMounted(() => {
      if (getLocalStorageLanguage()) return

      setLocalStorageLanguage(currentStoreView().storeCode)
    });

    watch(selectedLanguage, (storeCode) => {
      setLocalStorageLanguage(storeCode);
      window.location.assign(getStoreCodeLink(storeCode));
    })

    const isValidStoreCode = (storeCode) => {
      const storeView = config.storeViews[storeCode];
      return !!(storeView && typeof storeView === 'object' && storeView.i18n);
    }

    const selectLanguage = (storeCode) => {
      selectedLanguage.value = storeCode
    }

    const handleMouseEnter = () => {
      if (props.clickable) return
      isOpen.value = true
    };

    const handleMouseLeave = () => {
      if (props.clickable) return
      isOpen.value = false;
    };

    const clearTimer = () => {
      if (openTimer.value) return

      clearTimeout(openTimer.value)
      openTimer.value = null
    }

    const handleClick = () => {
      if (!props.clickable) return
      isOpen.value = !isOpen.value

      if (!isOpen.value) return

      clearTimer()

      openTimer.value = setTimeout(() => {
        lsSwitcher.value.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }, 200);
    };

    onUnmounted(() => {
      clearTimer()
    })

    return {
      isOpen,
      lsSwitcher,
      isExpand: props.isExpand,
      showAll: props.showAll,
      selectedLanguage,
      currentLabel,
      storeViews,
      handleMouseEnter,
      handleMouseLeave,
      handleClick,
      selectLanguage
    };
  }
});
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.s-lsm {
  justify-content: flex-end;

  &--show {
    .s-lsm__selector .s-lsm__select {
      pointer-events: all;
      top: 100%;
      opacity: 1;
    }

    .s-lsm__selector .s-lsm__select--expand {
      height: 60px;

      .s-lsm__option {
        min-height: 30px;
        padding-left: var(--spacer-20);
      }
    }
  }

  &__selector {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    font-size: var(--font-size-15);
    position: relative;

    .s-lsm__option {
      cursor: pointer;
      padding-left: var(--spacer-16);
      padding-right: var(--spacer-16);
      text-align: center;
      width: 100%;
      justify-content: flex-start;

      &:hover {
        color: var(--orange);
      }
    }

    .s-lsm__select {
      transition: 200ms all;
      opacity: 0;
    }

    .s-lsm__select--float {
      padding-top: var(--spacer-16);
      padding-bottom: var(--spacer-16);
      position: absolute;
      background: var(--white);
      box-shadow: var(--select-dropdown-box-shadow, 0 7px 9px rgb(159 159 159 / 10%));
      top: 0;
      left: -15px;
      right: -15px;
      pointer-events: none;
    }

    .s-lsm__select--expand {
      width: 100%;
      height: 0;
      overflow: hidden;
    }

    .s-lsm--selected {
      color: var(--orange);
      pointer-events: none;
    }

    .s-lsm--hidden {
      display: none;
    }
  }

  &__select {
    ::v-deep &.sf-select--is-active {
      .sf-select__selected {
        color: var(--orange);
      }
    }

    ::v-deep & > div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 4.5rem;
      padding: var(--spacer-2xs) 0 var(--spacer-2xs) var(--spacer-20);
      box-sizing: border-box;
    }

    @include for-desktop {
      display: block;
      --select-option-border: none;
      --select-dropdown-box-shadow: 0 11px 24px rgba(0, 0, 0, 0.06);
      --select-animation-enter: ssdesktop 0;
      --select-animation-leave: ssdesktop 0 reverse;
    }

    ::v-deep {
      .sf-select__options {
        max-height: 100%!important;
      }
    }
  }

  &--selected {
    display: none;
  }

  &__option {
    font-size: var(--font-sm);
    color: var(--black);
    padding: var(--spacer-5) var(--spacer-5) var(--spacer-5) var(--spacer-20);
    text-align: left;

    @include for-mobile {
      padding: 0;
      border: none;
      margin-left: var(--spacer-15);
    }
  }
}
</style>
