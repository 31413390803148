<template>
  <div
    class="banner-wrapper"
    :class="{'banner-wrapper__link': !checkButtonMode}"
    @click="goToBannerLink"
    :style="{ backgroundColor: backgroundStyle }"
  >
    <component
      v-if="banner"
      :is="componentBanner"
      :image="banner.image"
      :background="backgroundStyle"
      class="sf-banner-plp"
    >
      <template #description v-if="checkTitleMode">
        <div :class="isMobile ? 'sf-banner-plp-xm__wrap' : 'sf-banner-plp__wrap'">
          <p class="sf-banner-plp__description" :style="{color: banner.subtitle_color}">
            {{ banner.title }}
          </p>
        </div>
      </template>
      <template #call-to-action v-if="checkButtonMode">
        <SfLink
          :link="banner.link || '#'"
          :target="linkTarget"
          @click.native="onBannerClick(banner)"
        >
          <SfButton
            class="sf-button-main color-primary"
            :style="{color: banner.button_text_color, backgroundColor: banner.button_color}"
          >
            {{ banner.button_text }}
          </SfButton>
        </SfLink>
      </template>
    </component>
  </div>
</template>
<script>
import {
  SfButton,
  SfBanner,
  SfLink
} from '@storefront-ui/vue';
import SfBannerResponsive from 'theme/components/storefront-override/SfBannerResponsive';
import GoogleTagManager from 'theme/mixins/gtm';
import DeviceType from 'theme/mixins/DeviceType';
import { mapState } from 'vuex';

export default {
  name: 'MBannerInform',
  mixins: [DeviceType, GoogleTagManager],
  components: {
    SfButton,
    SfLink
  },
  props: {
    banner: {
      type: Object,
      default: () => ({})
    },
    isResponsive: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...mapState({
      getCategories: state => state.category.list
    }),
    componentBanner () {
      return this.isResponsive ? SfBannerResponsive : SfBanner
    },
    checkTitleMode () {
      return +this.banner?.show_title
    },
    checkButtonMode () {
      return +this.banner?.button_show
    },
    linkTarget () {
      return this.banner?.target || null
    },
    backgroundStyle () {
      return this.banner?.background_color || ''
    },
    bannerCategories () {
      return this.getCategories.filter(e => (this.banner.category_ids || []).includes(e.id))
        .map(e => e.name) || []
    }
  },
  methods: {
    goToBannerLink () {
      this.$emit('banner-click');
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~theme/css/px2rem";
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.banner-wrapper__link {
  cursor: pointer;
}

.sf-banner.sf-banner-plp {
  padding: var(--spacer-8);
  min-height: 90px;
  align-items: var(--banner-align-items, center);

  @media (min-width: $tablet-min) {
    min-height: 115px;
    padding: var(--spacer-12);
  }

  @include for-desktop {
    min-height: 150px;
  }

  @media (min-width: $mobile-max) {
    margin-left: calc(-1 * var(--spacer-10));
    margin-right: calc(-1 * var(--spacer-10));
    width: calc(100% + calc(2 * var(--spacer-10)));
  }

  &__description {
    font-size: var(--font-base);
    font-weight: var(--font-normal);
    line-height: var(--h3-font-size);
    margin-top: 0;
    margin-bottom: var(--spacer-13);

    @media (min-width: $mobile-max) {
      font-size: var(--font-lg);
      font-weight: var(--font-medium);
    }

    @include for-desktop {
      font-size: var(--font-xl);
      font-weight: var(--font-medium);
    }
  }

  .sf-banner__container {
    padding-left: px2rem(30);

    @include for-desktop {
      padding-left: px2rem(44);
    }

    .sf-button-main {
      font-size: var(--font-sm);
      line-height: var(--font-size-24);
      max-width: 131px;
      height: var(--spacer-40);

      @media (min-width: $tablet-min) {
        max-width: 171px;
      }
    }
  }
}

</style>
