<template>
  <ASmoothReflow>
    <div class="pre-header-banner-container">
      <MPreHeaderBannerSkeletonLoader v-if="isLoading" />
      <div
      v-if="!isLoading && showPreHeaderBanner && banner"
      class="pre-header-banner"
      :style="{ backgroundColor: backgroundStyle }"
      @click="goToBannerLink"
      ref="preHeader"
    >
      <SfOBanner
        :image="banner.image"
        class="sf-banner-pre-header"
      >
        <template #title v-if="checkTitleMode">
          <p class="sf-banner-pre-header__title" :style="{color: banner.title_color}">
            {{ banner.title }}
          </p>
        </template>
        <template #description v-if="checkSubTitleMode && !isMobile">
          <p class="sf-banner-pre-header__description" :style="{color: banner.subtitle_color}">
            {{ banner.subtitle }}
          </p>
        </template>

        <template #call-to-action v-if="checkButtonMode">
          <SfLink :link="banner.link || '#'" :target="linkTarget">
            <SfButton
              class="sf-banner-pre-header__button"
              :style="{color: banner.button_text_color, backgroundColor: banner.button_color}"
            >
              {{ banner.button_text }}
            </SfButton>
          </SfLink>
        </template>

        <template #close-button v-if="!checkCrossMode">
          <SfButton
            class="sf-button--pure sf-modal__close"
            aria-label="Close modal"
            @click.stop="accept"
          >
            <span class="icon-close" :style="{color: banner.button_color}" />
          </SfButton>
        </template>
        </SfOBanner>
      </div>
    </div>
  </ASmoothReflow>
</template>

<script>
import {
  SfButton,
  SfLink
} from '@storefront-ui/vue';
import SfOBanner from 'theme/components/storefront-override/SfOBanner';
import DeviceType from 'theme/mixins/DeviceType';
import { mapGetters, mapState } from 'vuex';
import { getThumbnailPath } from '@vue-storefront/core/helpers';
import MPreHeaderBannerSkeletonLoader from './m-pre-header-banner-skeleton-loader';
import ASmoothReflow from 'theme/components/atoms/a-smooth-reflow.vue';

const PREHEADER_BANNER_POSITION_INDEX = '5';

export default {
  name: 'MBannerPreHeader',
  mixins: [DeviceType],
  components: {
    SfButton,
    SfLink,
    SfOBanner,
    MPreHeaderBannerSkeletonLoader,
    ASmoothReflow
  },
  data () {
    return {
      isLoading: true
    }
  },
  mounted () {
    this.initializeBannerState();
  },
  computed: {
    ...mapGetters({
      getPreHeaderBanners: 'promoted/getPreHeaderBanners'
    }),
    ...mapState({
      showPreHeaderBanner: state => state.ui.preHeaderBanner,
      isPersonalAccountMenuOpen: state => state.ui.isAccountMenuOpen
    }),
    banner () {
      const filteredPreHeaderBanners = this.getPreHeaderBanners.filter((el) => {
        return el.position === PREHEADER_BANNER_POSITION_INDEX
      });
      filteredPreHeaderBanners.forEach(e => {
        e.image = {
          mobile: getThumbnailPath(e.image_mobile),
          desktop: getThumbnailPath(e.image_desktop)
        }
      });
      return filteredPreHeaderBanners[0]
    },
    checkTitleMode () {
      return +this.banner?.show_title
    },
    checkSubTitleMode () {
      return +this.banner?.show_subtitle
    },
    checkButtonMode () {
      return +this.banner?.button_show
    },
    linkTarget () {
      return this.banner?.target || null
    },
    backgroundStyle () {
      return this.banner?.background_color || '#F3F4F6'
    },
    checkCrossMode () {
      return this.banner?.allow_hide_button
    }
  },
  methods: {
    goToBannerLink () {
      const link = this.banner?.link || '';
      if (!this.checkButtonMode && !this.checkTitleMode) {
        this.$router.push(this.localizedRoute(link));
      }
    },
    accept () {
      this.$store.dispatch('ui/showPreHeaderBanner', false)
      sessionStorage.setItem('preHeaderAccepted', 'true');
    },
    initializeBannerState () {
      if (!this.checkIfPreHeaderAcceptedKeyExists()) {
        sessionStorage.setItem('preHeaderAccepted', 'false')
      }

      const isAccepted = sessionStorage.getItem('preHeaderAccepted') === 'true'
      this.$store.dispatch('ui/showPreHeaderBanner', this.banner && !isAccepted)
      this.isLoading = false
    },
    checkIfPreHeaderAcceptedKeyExists () {
      const value = sessionStorage.getItem('preHeaderAccepted')
      return value === 'true' || value === 'false'
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import '~theme/css/fonts';

.pre-header-banner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hide-pre-header {
  display: none;
}

.pre-header-banner {
  position: relative;
  width: 100%;
  z-index: 2;

  @include for-desktop {
    height: var(--pre-header-desktop-height);
    display: flex;
    align-items: center;
  }

  .sf-banner-pre-header {
    max-height: 50px !important;
    min-height: auto !important;
    padding: 0 !important;
    display: flex;
    align-items: center;
    height: 50px;

    @include for-desktop {
      max-height: 40px !important;
      height: 40px;
    }

    &__title {
      font-size: var(--font-size-12);
      line-height: var(--font-size-14);
      flex: 2 1 auto;
      text-align: center;
      font-weight: 400;
      padding: 0 var(--spacer-10);
    }

    &__description {
      flex: 1 1 150px;
      font-size: var(--font-size-12);
      padding-right: var(--spacer-50);
    }

    &__button {
      margin: 0 var(--spacer-40) 0 0;
      flex: 1 1 100px;
      padding: var(--spacer-5) var(--spacer-20);
      font-size: var(--font-size-12);

      @include for-desktop {
        margin: 0 var(--spacer-50) 0 0;
      }
    }
  }
  ::v-deep {
    .sf-banner__container {
      flex-direction: row;
      align-items: center;
      justify-content: center;
      max-width: var(--max-width);
      margin: 0 auto;
      flex: 1 1 auto;
      position: relative;

      .sf-modal__close {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background: transparent;
        right: 0;
        z-index: 0;

        .icon-close {
          @include font-icon(var(--icon-close));
          font-size: var(--font-size-24);
        }

        @include for-desktop {
          right: 10px;
        }
      }
    }
    .sf-banner {
      height: 40px;
      min-height: 40px;
      padding: 0;
    }
  }
}
</style>
