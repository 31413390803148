<template>
  <AAccountIcon
    :is-active="true"
    :caption="caption"
    @click="handleClick"
  />
</template>

<script>
import { computed, defineComponent, getCurrentInstance } from 'vue';
import AAccountIcon from '../../atoms/buttons/a-account-icon.vue';
import { localizedRoute } from '@vue-storefront/core/lib/multistore';
import { ModalList } from 'theme/store/ui/modals';

export default defineComponent({
  name: 'MAccountButton',

  components: {
    AAccountIcon
  },

  setup () {
    const { proxy: vm } = getCurrentInstance();
    const isLoggedIn = computed(() => vm.$store.getters['user/isLoggedIn'])

    const caption = vm.$t('My profile');

    const handleClick = () => {
      vm.$store.dispatch('ui/hideBottomNavigation', false);
      if (!isLoggedIn.value) {
        vm.$store.dispatch('ui/openModal', {
          name: ModalList.Auth,
          payload: 'auth'
        });

        return
      }

      vm.$store.commit('ui/closeMenu');
      vm.$router.push(localizedRoute('/my-account'));
    };

    return {
      caption,
      handleClick
    };
  }
});
</script>
