<template>
  <SfButton
    class="login-button sf-button--outline"
    :aria-label="$t('Login')"
    data-transaction-name="Login"
    @click="handleClick"
  >
    <span>{{ $t('Login') }}</span>
  </SfButton>
</template>

<script>
import { defineComponent, getCurrentInstance } from 'vue';
import { ModalList } from 'theme/store/ui/modals';
import { SfButton } from '@storefront-ui/vue';

export default defineComponent({
  name: 'MLoginButton',

  components: {
    SfButton
  },

  setup () {
    const { proxy: vm } = getCurrentInstance();

    const handleClick = () => {
      vm.$store.dispatch('ui/hideBottomNavigation', false);
      // vm.$store.commit('ui/closeMenu');

      vm.$store.dispatch('ui/openModal', {
        name: ModalList.Auth,
        payload: 'auth'
      });
    };

    return {
      handleClick
    };
  }
});
</script>

<style lang="scss" scoped>
.login-button {
  --button-font-size: 14px;
  --button-font-weight: 500;
  --button-padding: 8px 16px;
  --button-font-line-height: 24px;
}
</style>
